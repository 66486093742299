import React from "react";

const Resume = () => {
  return(
    <div className='m-6'>
      <h1>Raphael G. Villas</h1>
      <p>2000 Mallory Lane, Suite 130-525 | Franklin, TN 37067</p>
      <p>773.269.9601 |
        <a href='https://www.github.com/phirefly' target='_blank' rel="noopener noreferrer">github</a> |
        <a href='https://www.twitter.com/phirefly' target='_blank' rel="noopener noreferrer">twitter</a> |
        <a href='https://www.linkedin.com/in/raphaelvillas/' target='_blank' rel="noopener noreferrer">linkedin</a> |
        <a href='/work'>portfolio</a> |
        <a href='/contact'>contact</a>
        </p>

      <h2>As a <strong>digital craftsman,</strong> I bring a unique blend of creative front-end and technical back-end skills to every project, passionately delivering experiences that are aesthetically pleasing and technically sound. As a <strong>technology leader,</strong> I am dedicated to guiding my team toward excellence through a data-driven, user-centered design approach. Let's create <strong>something extraordinary together!</strong></h2>

      <h3>Experience</h3>
      <h5>DIRECTOR OF PRODUCT ENGINEERING, Click Funnels</h5>
      <em>Eagle, ID (January 2024 – February 2025)</em>

      <ul>
        <li style={{ listStyle: 'none'}}>Led engineering teams in delivering critical platform improvements and new features for Click Funnels' e-commerce and community platforms:</li>
        <li>Spearheaded the implementation of key e-commerce features including an Add to Cart functionality and store search capability, expanding platform capabilities for customers' online stores and improving the overall shopping experience</li>
        <li>Directed the development of enhanced Course/Community features, optimizing module access management and implementing daily digest notifications to improve user engagement and streamline internal operations</li>
        <li>Led the integration of Stripe and Apple Pay into the Editor Checkout Element, expanding payment options and improving conversion rates for customers' sales funnels</li>
        <li>Implemented SMS-based two-factor authorization flow, enhancing platform security while maintaining user experience</li>
      </ul>
      <h5>DIRECTOR OF PRODUCT ENGINEERING, Ramsey Solutions</h5>
      <em>Franklin, TN (April 2018 – January 2024)</em>
      <ul>
        <li>Led cross-functional engineering teams in delivering enterprise-scale digital products for Financial Peace Partnerships and Ramsey+ Coaching, driving product innovation while maintaining technical excellence</li>
        <li>Orchestrated migration to KonaKart-based e-commerce platform, implementing digital licensing system while ensuring seamless transition for existing customer base</li>
        <li>Spearheaded launch of Ramsey+ B2B platform, architecting scalable solution that enabled church-wide access to digital products, driving significant revenue growth</li>
        <li>Led architectural overhaul of business-critical Salesforce integration, implementing Backend-for-Frontend (BFF) architecture that reduced cycle time from 4.5 to 1.5 days</li>
        <li>Automated key acquisition processes through third-party integrations, optimizing webinar delivery system and improving operational efficiency</li>
        <li>Established engineering best practices and team workflows that consistently improved delivery speed while maintaining code quality</li>
        <li>Partnered with Security team to implement comprehensive application monitoring and penetration testing, significantly enhancing organization's security posture</li>
        <li>Collaborated with Sales and Customer Success teams to optimize user experience, resulting in >50% reduction in support tickets across multiple categories</li>
        {/* Resulted in an immediate XX% increase in squad throughput  */}
      </ul>

      <h5 className="pt-5">INSTRUCTOR: ROBOTICS, PRINTING, DIGITAL MEDIA | 4-H Youth Development</h5>
      <em>Franklin, TN (August 2020 – Present)</em>
      <ul>
        <li>Designed and planned six-week curriculums for beginner through advanced students using the LEGO EV3 and Spike Prime platforms, focused on bolstering STEM skills</li>
        <li>Encouraged student creativity and critical thinking through hands-on robotics projects covering fundamental robotics concepts and computer programming skills</li>
        <li>Cultivated strong relationships with students, fostering a positive and inclusive learning environment, and inspiring a lifelong passion for technology and engineering</li>
        {/* <li>Coached students through coding and build issues </li> */}
      </ul>

      <h5 className="pt-5">TRANSFORMATION LEAD | Department of Labor, Wage and Hour Division</h5>
      <em>Chicago, IL (December 2016 – April 2018)</em>
      <ul>
      <li>Successfully coordinated the modernization of legacy systems by synthesizing a unified plan of action across various program areas, improving organizational efficiency and effectiveness</li>
      <li>Spearheaded operating plan initiatives for Wage and Hour IT modernization and business process enhancement, leading to heightened productivity and improved processes</li>
      <li>Facilitated workshops across channel areas, including strategic planning, retrospectives, and product discovery</li>
      <li>Enacted agile methodologies to streamline project delivery and ensure efficient use of resources, resulting in high-quality deliverables and improved project outcomes</li>
      </ul>

      <h5 className="pt-5">CO-FOUNDER + PRODUCT LEAD | 18F, General Services Administration</h5>
      <em>Chicago, IL (December 2013 – December 2016)</em>
      <ul>
        <li>Led cross-functional product teams in delivering cloud-native applications using modern development practices and microservices architecture</li>
        <li>Established and scaled the Trusted Tester Program using data-driven UX research methodologies, recruiting 50+ beta testers to validate user-centered design implementations</li>
        <li>Architected and launched the C2/Communicart Approval Tool, implementing RESTful APIs and event-driven architecture to streamline federal procurement, processing over $2.5M in requests</li>
      </ul>

      <h5 className="pt-5">PRESIDENTIAL INNOVATION FELLOW | The White House, GSA</h5>
      <em>Washington, D.C. (June 2013 – December 2013)</em>
      <ul>
        <li>Contributed to the development of <a href='https://www.login.gov' target='_blank'>Login.gov</a>'s predecessor (MyUSA), implementing OAuth 2.0 authentication and building secure microservices using modern web frameworks</li>
        <li>Developed scalable task management microservice using test-driven development and continuous integration/deployment practices</li>
        <li>Built responsive web applications following federal digital service standards, ensuring accessibility and cross-platform compatibility</li>
      </ul>

      <h5 className="pt-5">SOFTWARE ENGINEER | Groupon, Inc.</h5>
      <em>Chicago, IL (March 2011 – June 2013)</em>
        <ul>
        <li>Engineered critical e-commerce infrastructure including a distributed gift card processing engine and automated rewards fulfillment system, supporting high-volume transaction processing</li>
        <li>Implemented a client-side application using Backbone.js and RESTful APIs, providing real-time transparency for merchant payment systems</li>
        <li>Supported existing automated testing infrastructure, ensuring comprehensive code coverage and continuous integration practices</li>
        <li>Facilitated knowledge transfer and training for international team members in Berlin, enabling successful deployment of new invoice processing systems.</li>
        </ul>

      <h5 className="pt-5">OWNER / DESIGNER | 2 Big Division, Inc.</h5>
      <em>Chicago, IL (July 2003 – March 2011)</em>

      <ul>
        <li>Led full-cycle digital transformation projects, implementing user-centered design methodologies from discovery through deployment for enterprise clients</li>
        <li>Architected responsive, accessible digital experiences focusing on conversion optimization and engagement metrics for diverse client portfolios</li>
        <li>Established strategic partnerships with industry leaders including Hill's Pet Nutrition and VML, delivering integrated digital solutions across web, mobile, and brand touchpoints</li>
        <li>Developed and executed comprehensive digital strategies encompassing information architecture, UX/UI design, and performance optimization</li>
        <li>Built and maintained long-term client relationships through data-driven results and iterative improvement processes, serving notable organizations including The Cara Program, Epic Academy, Faith in Place, and Clean Slate</li>
      </ul>

      <h5 className="pt-5">WEB / INTERACTIVE DESIGNER | Big Idea Productions</h5>
      <em>Lombard, IL (June 1999 – April 2003)</em>

      <ul>
      <li>Developed immersive digital experiences for major entertainment properties including VeggieTales, 3-2-1 Penguins!, and LarryBoy, driving brand engagement across platforms</li>
      <li>Led a remote cross-functional team in designing and developing 'BigIdeaFun,' a children's gaming website, ensuring consistent design and technical standards throughout the project</li>
      <li>Applied user-centered design principles to produce engaging and intuitive interfaces that effectively promote Big Idea's properties</li>
      </ul>

      <h5 className="pt-5">STAFF CONSULTANT | Metamor Technologies</h5>
      <em>Chicago, IL (April 1997 – May 1999)</em>
      <ul>
      <li>Pioneered early interactive web experiences through rapid prototyping of user interfaces, establishing foundations for sound front-end development practices</li>
      <li>Established client collaboration frameworks for requirements gathering and iterative development, driving successful project outcomes</li>
      <li>Created technical documentation and implementation guidelines ensuring consistent development practices across project teams</li>
      </ul>

      <h3>Skills</h3>
      <h5>Engineering</h5>
        <p>Languages: Ruby | Javascript | Go | HTML/CSS</p>
        <p>Technologies: AWS | Ruby on Rails | React / Nextjs / T3 Stack | Tailwind</p>
        <p>Other: Relational / NoSQL Databases | Test-Driven Development</p>
      <h5>Product</h5>
        <p>Strategic Thinking + Planning | Agile Methodologies | Team Leadership | Product Thinking</p>
      <h5>Design</h5>
        <p>User Research | Visual Design | Interaction Design | Lean UX</p>
      <h5>Tools</h5>
        <p>Github | Jira / Pivotal Tracker / Linear / Trello</p>

      <h3>Education</h3>
      <ul>
        <li>Post-Baccalaureate Studies, Graphic Design | University of Illinois at Chicago</li>
        <li>Interactive Media Studies | International Academy of Art and Design</li>
        <li>Bachelor of Science in Nursing | Loyola University of Chicago</li>
      </ul>

      <h3>Affiliations</h3>
      <ul>
      <li>Toastmasters International | Harpeth View Toastmasters </li>
      <li>Rolling Hills Community Church | Family Ministry Leader</li>
      </ul>

      <h3>Recognition</h3>
      <ul>
        <li>Ramsey Solutions | Ramsey+ Emergence Award</li>
        <li>Accelerating Government Nominee | ACT-AIC Igniting Innovation Award</li>
        <li>General Services Administration | Top Team C.R.E.W. Award</li>
      </ul>
    </div>
  )
}

export default Resume;
